import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"

const NotFoundPage = () => {
  const getPath= typeof window!=="undefined"?window.location.pathname:""
  const splitPath=getPath?.split("/")
  const getOffplanPath=splitPath?.length>1?splitPath[1]:""
  const isLoading=getOffplanPath==="";
  return(
  <Layout>
    <div className="not-found-page">
      <Container>
        {isLoading?
        <div className="error-page-containers">
          <div className="loader-container-404">
          <div className="loader-404"></div>
          </div>
        </div>
        :
        <div className="error-page-containers">
          <div className="logo_image"></div>
          <div className="error-page-content">
            {getOffplanPath==="new-developments-details"?
            <h2>Unfortunately, the property listing page you are looking for is no longer available</h2>
            :
            <h2>WE'RE SORRY, THE PAGE YOU WERE LOOKING FOR CANNOT BE FOUND.</h2>
            }
             {getOffplanPath==="new-developments-details"?
            <p>Try a different search or return to our home page to find what you need</p>
            :
            <p>
              This may be because it has been removed, we've changed its name or
              it is temporarily unavailable.
            </p>
            }
          </div>
          <div className="eror-page-ctas">
            {getOffplanPath==="new-developments-details"&&
             <Link className="button button-outline-white" to="/off-plan-properties/for-sale/in-dubai/">
               Offplan Properties
              </Link>
            }
            <Link className="button button-filled-green" to="/">
              Back to Home Page
            </Link>
            
            <Link className="button button-outline-white" to="/contact/">
              Contact
            </Link>
          </div>
        </div>
      }
      </Container>
    </div>
  </Layout>
)
  }

export const Head = () => <Seo title="404: Not Found" imageUrl={`${process.env.GATSBY_SITE_URL}/images/mail-logo.png`}/>

export default NotFoundPage
